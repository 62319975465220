import {useEffect} from "react";

const _ = require('lodash');

const useInfoForm = (values, setValues) => {

    useEffect(() => {
        const newValues = calculateFields(values);
        console.log("Setting info values", newValues);
        setValues(newValues);
    }, []);

    const calculateFields = (v) => {
        let newValues = _.cloneDeep(v);
        Object.keys(v).map(key => {
            const field = v[key];
            if ('addFields' in field) {
                Object.keys(field.addFields).map(f => {
                    newValues[f] = _.cloneDeep(v[key].addFields[f]);
                    newValues[f].position = Number(field.position + "." + newValues[f].position);
                    newValues[f].hidden = field.value!==field.addFieldsOn;
                })
            }
            newValues[key].hidden = isHidden(newValues, key);
        });
        return newValues;
    }

    const isHidden = (values, key) => {
        const field = values[key];
        if ('hideOn' in field) {
            const hideOn = Array.isArray(field.hideOn) ? field.hideOn : [field.hideOn];
            for(let i=0;i<hideOn.length;i++) {
                const hide = hideOn[i]
                if (hide.field in values) {
                    if ('notValue' in hide) {
                        if (values[hide.field].value !== hide.notValue) {
                            return true;
                        }
                    }
                    if ('value' in hide) {
                        if (values[hide.field].value === hide.value) {
                            return true;
                        }
                    }
                    if ('regex' in hide) {
                        const re = new RegExp(hide.regex);
                        if (re.test(values[hide.field].value)) {
                            return true;
                        }
                    }
                }
            }
        }
        if ('showOn' in field) {
            const showOn = Array.isArray(field.showOn) ? field.showOn : [field.showOn];
            for(let i=0;i<showOn.length;i++) {
                const show = showOn[i];
                if (show.field in values) {
                    if ('notValue' in show) {
                        if (values[show.field].value !== show.notValue) {
                            return false;
                        }
                    }
                    if ('value' in show) {
                        if (values[show.field].value === show.value) {
                            return false;
                        }
                    }
                    if ('regex' in show) {
                        const re = new RegExp(show.regex);
                        if (re.test(values[show.field].value)) {
                            return false;
                        }
                    }
                }
            }
            return true;
        }
        return false;
    }

    const isError = (key) => {
        const field = values[key];
        if ('hidden' in field && field.hidden===true) return false;

        if ('required' in field && field.value === '') {
            return true;
        }
        if ('sameAs' in field && field.value !== values[field.sameAs].value)
            return true;

        if ('regex' in field) {
            const re = new RegExp(field.regex);
            if (!re.test(field.value)) {
                if ('required' in field)
                    return true;
                if (field.value === '')
                    return false;
                return true;
            }
        }
        return false;
    }

    const hasErrors = () => {
        return Object.keys(values).some(v => isError(v));
    }

    const changeValue = (key, value) => {
        if (values[key]===value) return;
        let newValues = _.cloneDeep(values);
        const field = newValues[key];
        field.value = value;
        checkHidden(newValues, key);
        console.log("Setting changeValue", newValues);
        setValues(newValues);
    }

    const checkHidden = (values, key) => {
        Object.keys(values).map(key => {
            values[key].hidden = isHidden(values, key);
        });
        // This needs to be done after previous loop so we can hide addFields
        Object.keys(values).map(key => {
            addFields(values, key);
        })

    }

    const addFields = (values, key) => {
        const field = values[key];
        if ('addFields' in field) {
            if ((!('addFieldsOn' in field) || field.addFieldsOn === field.value) && !field.hidden) {
                Object.keys(field.addFields).map(f => {
                    if (f in values)
                        values[f].hidden = false;
                });
            } else {
                Object.keys(field.addFields).map(f => {
                    if (f in values)
                        values[f].hidden = true;
                });
            }
        }
    }

    const handleChecked = name => event => {
        event.persist();
        changeValue(name, event.target.checked);
    }

    const handleEvent = (event) => {
        event.persist();
        const key = event.target.name;
        const value = event.target.value;
        changeValue(key, value);
    }

    const handleChange = key => value => {
        changeValue(key, value);
    }

    return {
        handleEvent,
        handleChange,
        handleChecked,
        isError,
        hasErrors,
        values,
        calculateFields,
        checkHidden,
        changeValue
    }
}

export default useInfoForm;
