import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {makeStyles} from "@material-ui/core";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {createInformationField} from "../../modules/information/infoFields";
import useInfoForm from "../../modules/forms/useInfoForm";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh'
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const MemberDashboard = ({}) => {
    const classes = useStyles();
    const [logo, setLogo] = useState("");

    let trip = useRouteMatch({
        path: "/tur/:uuid/",
        sensitive: true
    });
    const [tripUuid, setTripUuid] = useState(trip ? trip.params.uuid : "0");
    const [editorFrontpage, setEditorFrontpage] = useState({
        frontpage: {
            label: 'Hjem-siden',
            type: 'editor',
            value: '',
            position: 1,
            readonly: true,
            menu: false
        },
    });
    const editor1form = useInfoForm(editorFrontpage, setEditorFrontpage);


    const fetchTripData = async () => {
        await Fetch(API_BASE_URL + "/trip/" + tripUuid, 'GET', {}).then(result => {
            let newValues3 = _.cloneDeep(editorFrontpage);
            newValues3.frontpage.value = result.data.frontpage;
            setEditorFrontpage(newValues3);
            setLogo(result.data.logoUuid);
        })
    };

    useEffect(() => {
        fetchTripData();
    }, []);

    return (
        <>
            <Container className={classes.root}>
                <Row className="justify-content-center setup-trip" md={8}>
                    <Container>
                        <div>
                            {
                                createInformationField({values: editorFrontpage, key: 'frontpage', form: editor1form})
                            }
                        </div>
                        <Grid container justify="center">
                            {logo && logo !== "" &&
                            <img className="mb-2" src={API_BASE_URL + "/image/" + logo} style={{height: '150px'}}
                                 className="img-fluid"/>
                            }
                        </Grid>
                    </Container>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(MemberDashboard);

export default withRouter(connected);
