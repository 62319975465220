import {all} from 'redux-saga/effects'

import AuthSagas from './components/auth/sagas';
import UserSagas from './components/user/sagas';
import AdminSagas from './components/admin/sagas';

export default function* IndexSaga() {
  yield all([
    AuthSagas(),
    UserSagas(),
    AdminSagas(),
  ])
}
