import React, {Component} from 'react';
import './App.css';
import Dashboard from "./components/trip/Dashboard";
import {Route, withRouter} from "react-router-dom";
import Login from "./components/auth/login/AuthLogin";
import {PrivateRoute} from "./components/PrivateRoute";
import {Redirect, Switch} from "react-router";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {loggedInRequest} from "./actions";
import Admin from "./components/admin";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from "./components/auth/login/ResetPassword";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CookieConsent from "react-cookie-consent";
import {GDPR} from "./components/user/gdpr";
import CreateTrip from "./components/trip/CreateTrip";
import AddMember from "./components/trip/AddMember";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import SystemSetup from "./components/trip/SystemSetup";
import TripHeader from "./components/trip/TripHeader";
import MemberDashboard from "./components/trip/MemberDashboard";
import TripProgram from "./components/trip/TripProgram";
import TripMembers from "./components/trip/TripMembers";
import Reload from "./modules/reload/Reload";

class App extends Component {
    constructor(props) {
        super(props);

        const user = localStorage.getItem("auth");
        if (user) {
            this.props.loggedInRequest();
        }
        this.state = {
            toggle: false,
            windowWidth: 0,
            currentPage: '',
            sideNavToggled: false,
            breakWidth: 1400
        };
    }

    render() {

        return (
            <>
                <ThemeProvider theme={theme}>
                    <Reload/>
                    <CookieConsent
                    location="bottom"
                    buttonText="Jeg godtar"
                    cookieName="letphotoCookieConsent"
                    style={{background: "#C62622"}}
                    buttonStyle={{background: "#000000", color: "#ffff", fontSize: "13px"}}
                    expires={150}
                >
                    Denne websiden bruker cookies for å håndtere bruker-innlogging.{" "}
                    <span>Les vår <a href="/personvern" className={"black-text"}>personvernsærklæring</a></span>
                </CookieConsent>
                <ToastContainer autoClose={8000} toastClassName="dark-toast"/>
                    <Route path="/" component={TripHeader}/>
                <Switch>
                    <Route exact path="/"><Redirect to="/dashboard"/></Route>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/resetPassword/:token" exact component={ResetPassword}/>
                    <Route path="/personvern" exact component={GDPR}/>
                    <Route path="/tur/:tripId/paamelding" component={AddMember}/>
                    <Route path="/tur/:tripId" exact component={MemberDashboard}/>
                    <Route path="/tur/:tripId/program" exact component={TripProgram}/>
                    <Route path="/tur/:tripId/deltakere" component={TripMembers}/>
                    <React.Fragment>
                        <PrivateRoute path="/dashboard" exact component={Dashboard}/>
                        <PrivateRoute path="/admin" component={Admin}/>
                        <PrivateRoute path="/tur/:tripId/rediger" component={CreateTrip}/>
                        <PrivateRoute path="/setup" component={SystemSetup}/>
                    </React.Fragment>
                </Switch>
                </ThemeProvider>
            </>
        );
    }
}

App.propTypes = {
    loggedInRequest: PropTypes.func
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {loggedInRequest})(App);

export default withRouter(connected);
