import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 8px;
    height: 12px;
`;

export const SortIcon = ({ sort }) => {
    switch (sort) {
        case "desc":
            return (
                <Svg
                    className="SortIcon"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 0L7.4641 4.5L0.535898 4.5L4 0Z" fill="#A7B9DC" />
                </Svg>
            );
        case "asc":
            return (
                <Svg
                    className="SortIcon"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 12L0.535899 7.5L7.4641 7.5L4 12Z" fill="#A7B9DC" />
                </Svg>
            );
        default:
            return (
                <Svg
                    className="SortIcon"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 0L7.4641 4.5L0.535898 4.5L4 0Z" fill="#A7B9DC" />
                    <path d="M4 12L0.535899 7.5L7.4641 7.5L4 12Z" fill="#A7B9DC" />
                </Svg>
            );
    }
};

export default SortIcon;
