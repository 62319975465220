import React from "react";
import { toast } from 'react-toastify';

export const showError = (error) => {
    toast.error(<span>Feil: {error.toString().substr(7)}</span>)
}

export const showErrorText = (text) => {
    toast.error(<span>Feil: {text}</span>)
}

export const showInfo = (info) => {
  toast.info(<span>{info}</span>);
}
