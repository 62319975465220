import {connect} from "react-redux";
import {withRouter} from "react-router";
import React, {useState} from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
  MDBView
} from "../../../mdbreact";
import {API_BASE_URL} from "../../../constants";
import {Fetch} from "../../../common/lib/api-fetch";
import {showInfo} from "../../../common/lib/show-toast";
import TextField from "@material-ui/core/TextField";
import {ButtonBlack} from "../../../common/components/color-button";

const ResetPassword = ({history, match}) => {
    const [form, setForm] = useState({
        email: {changed: false, value: ''},
        newPassword: {changed: false, value: ''},
        newPassword2: {changed: false, value: ''}
    });

    const token = match.params.token;

    const submit = async (event) => {
        event.preventDefault();
        try {
            await Fetch(API_BASE_URL + "/auth/resetPassword", 'POST', {
                token: token,
                password: form.newPassword.value
            }).then(result => {
                showInfo("Passordet ditt er endret");
                history.push("/login");
            })
        } catch(error) {
            showInfo("Noe gikk galt i endringen av passordet");
        }
    };

    const changeHandler = event => {
        setForm({
            ...form,
            [event.target.name]: {changed: true, value: event.target.value}
        })
    };

    return (
        <>
            <div>
                <form className="needs-validation" onSubmit={submit} noValidate>
                    <div className="classic-form-page" id="login">
                        <MDBView>
                            <MDBMask
                                className="d-flex justify-content-center align-items-center"
                            >
                              <MDBContainer>
                                <MDBRow>
                                  <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                                    <MDBCard className="white black-text">
                                      <MDBCardBody>
                                        <div className="form-header white black-text">
                                          <h3><MDBIcon icon="user"
                                                       className="mt-2 mb-2"/> Sett passordet:</h3>
                                        </div>
                                        <TextField
                                          id="newPassword"
                                          label="Passord"
                                          name={"newPassword"}
                                          placeholder="Passord"
                                          margin="normal"
                                          variant="outlined"
                                          type="password"
                                          value={form.newPassword.value}
                                          onChange={changeHandler}
                                          minLength={4}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          fullWidth
                                        />
                                        <div className={"ml-3 small text-danger "+ (form.newPassword.value.length>=4 ? "d-none": "")}>Må være 4 tegn eller lengre</div>
                                        <TextField
                                          id="newPassword2"
                                          label="Passordet igjen"
                                          name={"newPassword2"}
                                          placeholder="Passordet igjen"
                                          margin="normal"
                                          variant="outlined"
                                          type="password"
                                          value={form.newPassword2.value}
                                          onChange={changeHandler}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          fullWidth
                                        />
                                        <div className={"ml-3 small text-danger "+ (form.newPassword.value===form.newPassword2.value ? "d-none": "")}>Må være lik</div>
                                        <div className="text-center mt-3 black-text">
                                          <ButtonBlack
                                            type="submit"
                                            disabled={!form.newPassword.changed || !form.newPassword2.changed || form.newPassword.value !== form.newPassword2.value || form.newPassword.value.length<4}
                                          >Endre passord</ButtonBlack>
                                        </div>
                                      </MDBCardBody>
                                    </MDBCard>
                                  </MDBCol>
                                </MDBRow>
                              </MDBContainer>
                            </MDBMask>
                        </MDBView>
                    </div>
                </form>
            </div>
            </>
                )
}

const mapStateToProps = state => ({
});

const connected = connect(mapStateToProps, {})(ResetPassword);

export default withRouter(connected);
