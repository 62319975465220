import React from "react";
import styled from "styled-components";

const LoadingSpinnerStyled = styled.section`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 60px;
        height: 60px;
    }

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 48px;
        height: 48px;
        margin: 6px;
        border: 6px solid #000;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #000 transparent transparent transparent;
    }

    .light.lds-ring div {
        border-color: #ccc transparent transparent transparent;
    }

    .small.lds-ring {
        width: 20px;
        height: 20px;
    }

    .small.lds-ring div {
        width: 18px;
        height: 18px;
        margin: 2px;
        border-width: 2px;
    }

    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const LoadingSpinner = ({ small, light }) => (
    <LoadingSpinnerStyled>
        <div className={"lds-ring" + (small ? " small" : "") + (light ? " light" : "")}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </LoadingSpinnerStyled>
);

export default LoadingSpinner;
