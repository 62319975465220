import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";
import React, {useEffect, useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {makeStyles} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './index.scss';
import useInfoForm from "../../modules/forms/useInfoForm";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import Col from "react-bootstrap/Col";
import {
    createInformationField,
    FieldsToRows,
    informationKeysInOrder,
    setFetchData
} from "../../modules/information/infoFields";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import AddField from "../../modules/forms/AddField";

import _ from 'lodash';
import {toast} from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh'
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const CreateTrip = ({history}) => {
    const classes = useStyles();
    const [showAddField, setShowAddField] = useState(false);
    const [addFieldValid, setAddFieldValid] = useState(false);
    let newFieldRef = useRef(null);
    const [currentExtraField, setCurrentExtraField] = useState(null);

    let trip = useRouteMatch({
        path: "/tur/:uuid/",
        sensitive: true
    });
    const [tripUuid, setTripUuid] = useState(trip ? trip.params.uuid : 0);

    const fetchTripData = async () => {
        await Fetch(API_BASE_URL + "/trip/" + tripUuid, 'GET', {}).then(result => {
            let data = result.data;
            setFetchData({values, setValues, data});
            setFetchData({values: editorFrontpage, setValues: setEditorFrontpage, data});
            setFetchData({values: editorUnconfirmedEmail, setValues: setEditorUnconfirmedEmail, data});
            setFetchData({values: editorConfirmedEmail, setValues: setEditorConfirmedEmail, data});
            setExtraFields(data.extra);
        })
    };

    const fetchSetupData = async () => {
        await Fetch(API_BASE_URL + "/trip/setup", "GET").then(result => {
            if (result) {
                let newValues1 = _.cloneDeep(editorUnconfirmedEmail);
                newValues1.unconfirmedEmail.value = result.data.unconfirmedEmail;
                setEditorUnconfirmedEmail(newValues1);
                let newValues2 = _.cloneDeep(editorConfirmedEmail);
                newValues2.confirmedEmail.value = result.data.confirmedEmail;
                setEditorConfirmedEmail(newValues2);
                let newValues3 = _.cloneDeep(editorFrontpage);
                newValues3.frontpage.value = result.data.frontpage;
                setEditorFrontpage(newValues3);
            } else {
                toast.error("Kunne ikke hente data");
            }
        })
    }

    const [extraFields, setExtraFields] = useState({})
    const [values, setValues] = useState({
        locked: {
            label: 'Lukket for påmelding',
            type: 'boolean',
            value: false,
            position: 0,
            helperText: 'Slå på hvis det skal være umulig å melde på flere personer'
        },
        name: {
            label: 'Navn',
            type: 'text',
            value: '',
            position: 1,
            required: 'Du må fylle ut navn på turen',
            regex: '\\w+',
            helperText: 'Navnet på turen'
        },
        companyName: {
            label: 'Firma',
            type: 'text',
            value: '',
            position: 1.1,
            required: 'Du må fylle ut firmanavn',
            regex: '\\w+',
            helperText: 'Intern informasjon om firmanavn'
        },
        fromDate: {
            label: 'Start dato',
            type: 'date',
            value: Date.now(),
            position: 2,
            required: 'Du må velge en start dato',
            helperText: 'Når starter turen'
        },
        toDate: {
            label: 'Slutt dato',
            type: 'date',
            value: Date.now(),
            position: 3,
            required: 'Du må velge en slutt dato',
            helperText: 'Når er turen ferdig'
        },
        changeDate: {
            label: 'Slutt på endringer',
            type: 'date',
            value: Date.now(),
            position: 3,
            helperText: 'Hvilken dato stopper tilgangen for endringer fra kunden'
        },
        doubleRooms: {
            label: 'Dobbeltrom',
            type: 'number',
            value: 0,
            position: 4,
            helperText: 'Hvor mange dobbeltrom er tilgjengelig'
        },
        singleRooms: {
            label: 'Enkeltrom',
            type: 'number',
            value: 0,
            position: 5,
            helperText: 'Hvor mange enkeltrom er tilgjengelig'
        },
        singleRoomExtraCost: {
            label: 'Ekstra kostnad enkeltrom',
            type: 'number',
            value: 0,
            position: 6,
            helperText: 'Ekstra kostnad for enkeltrom'
        },
        programUrl: {
            label: 'Travefy embed lenke',
            type: 'text',
            value: '',
            position: 10
        },
        hiddenMemberList: {
            label: 'Skjul deltakerliste',
            type: 'boolean',
            value: false,
            position: 10.5,
            helperText: 'Kun administrator vil se deltakerlisten'
        },
        closedList: {
            label: 'Lukket tur',
            type: 'boolean',
            value: true,
            position: 10.6,
            helperText: 'Du må være logget inn for å se deltakerlisten'
        },
        showSASeurobonus: {
            label: 'Vis SAS eurobonus',
            type: 'boolean',
            value: false,
            position: 11
        },
        companion: {
            label: 'Tillat ledsager',
            type: 'boolean',
            value: false,
            position: 12
        },
        logoUuid: {
            label: 'Logo',
            type: 'image',
            value: null,
            position: 16
        },
        firstStatus: {
            label: 'Første status',
            type: 'select',
            possibleValues: [{key: 'bekreftet', value: 'Bekreftet'}, {key: 'ubekreftet', value: 'Ubekreftet'}],
            value: 'ubekreftet',
            position: 15
        },
        emails: {
            label: 'Admin epost-adresser, kommaseparert',
            placeholder: 'epost@epost.no,epost2@epost.no',
            type: 'text',
            value: '',
            position: 17
        },
        passportNumber: {
            label: 'Vis passnummer-felt',
            type: 'boolean',
            value: false,
            position: 13
        },
        passport: {
            label: 'Vis pass',
            type: 'boolean',
            value: false,
            position: 12.9
        },
        showCompanyFields: {
            label: 'Firmainformasjon',
            type: 'boolean',
            value: false,
            position: 14
        },
    });

    const [editorFrontpage, setEditorFrontpage] = useState({
        frontpage: {
            label: 'Hjem-siden',
            type: 'editor',
            value: '',
            position: 1
        },
    });

    const [editorUnconfirmedEmail, setEditorUnconfirmedEmail] = useState({
        unconfirmedEmail: {
            label: 'Ubekreftet epost',
            type: 'editor',
            value: '',
            position: 1
        },
    });

    const [editorConfirmedEmail, setEditorConfirmedEmail] = useState({
        confirmedEmail: {
            label: 'Bekreftet epost',
            type: 'editor',
            value: '',
            position: 1
        }
    })

    const form = useInfoForm(values, setValues);
    const editor1form = useInfoForm(editorFrontpage, setEditorFrontpage);
    const editor2form = useInfoForm(editorUnconfirmedEmail, setEditorUnconfirmedEmail);
    const editor3form = useInfoForm(editorConfirmedEmail, setEditorConfirmedEmail);

    useEffect(() => {
        if (tripUuid !== "0") {
            fetchTripData();
        } else {
            fetchSetupData();
        }
    }, [tripUuid]);

    const addField = () => {
        const v = newFieldRef.current.values;
        let position = v.position.value!=="" ? v.position.value : Object.keys(extraFields).map(v => extraFields[v].position).concat(Object.keys(values).map(v => values[v].position)).sort((a, b) => b - a).shift() + 1;

        const newExtraFields = _.cloneDeep(extraFields);
        const newField = {
            label: v.label.value,
            type: v.type.value,
            value: '',
            position: position,
            helperText: v.helperText.value
        }
        let fieldName = currentExtraField ? currentExtraField : 'field_' + newField.position;
        newExtraFields[fieldName] = newField;
        setExtraFields(newExtraFields);
        setCurrentExtraField(null);
        setShowAddField(false);
    }

    const saveNewTrip = async () => {
        let data = {};
        for (let key of Object.keys(values)) {
            data[key] = values[key].value;
        }
        data.uuid = tripUuid;
        data.unconfirmedEmail = editorUnconfirmedEmail.unconfirmedEmail.value;
        data.confirmedEmail = editorConfirmedEmail.confirmedEmail.value;
        data.frontpage = editorFrontpage.frontpage.value;
        data.extra = extraFields;

        await Fetch(API_BASE_URL + "/trip/" + tripUuid, "POST", data).then(result => {
            history.push("/tur/" + result.data.uuid);
        })
    }

    return (
        <>
            <Container className={classes.root}>
                <Row className="justify-content-center setup-trip" md={8}>
                    <Container>
                        <form noValidate autoComplete="off">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <>
                                    <Row className="mt-3">
                                        <Col>
                                            <h2>{tripUuid === "0" && `Ny tur`}{tripUuid !== "0" && `Endre tur`}</h2>
                                            <p className={"instruction"}>
                                                Fyll inn informasjonen om turen
                                            </p>
                                        </Col>
                                    </Row>
                                    {
                                        FieldsToRows({values, classes, infoForm: form})
                                    }
                                    {
                                        createInformationField({
                                            values: editorFrontpage,
                                            key: 'frontpage',
                                            form: editor1form
                                        })
                                    }
                                    {
                                        createInformationField({
                                            values: editorUnconfirmedEmail,
                                            key: 'unconfirmedEmail',
                                            form: editor2form
                                        })
                                    }
                                    {
                                        createInformationField({
                                            values: editorConfirmedEmail,
                                            key: 'confirmedEmail',
                                            form: editor3form
                                        })
                                    }
                                    {Object.keys(extraFields).length > 0 &&
                                    <>
                                        <Row>
                                            <Col className={"mt-2"}><h3>Ekstrafelt</h3></Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <b>Navn</b>
                                            </Col>
                                            <Col md={4}>
                                                <b>Type</b>
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Row>
                                        {informationKeysInOrder(extraFields).map(key => {
                                            return <Row id={key} key={key}>
                                                <Col md={4} className={"mt-1"}>
                                                    {extraFields[key].label}
                                                </Col>
                                                <Col md={4} className={"mt-1"}>
                                                    {extraFields[key].type}
                                                </Col>
                                                <Col md={4}>
                                                    <Button color="primary" onClick={() => {
                                                        setCurrentExtraField(key);
                                                        setShowAddField(true)
                                                    }}>Endre</Button>
                                                    <Button color="secondary" onClick={() => {
                                                        const ne = _.cloneDeep(extraFields);
                                                        delete ne[key];
                                                        setExtraFields(ne)
                                                    }}>Slett</Button>
                                                </Col>
                                            </Row>
                                        })
                                        }
                                    </>
                                    }
                                    <Row>
                                        <Col>
                                            <Button className="mt-2" color="primary" variant={"contained"}
                                                    onClick={() => setShowAddField(true)}>Legg til felt</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{offset: 10}}>
                                            <Button className="my-2" color="secondary" variant="contained"
                                                    disabled={form.hasErrors()}
                                                    onClick={() => saveNewTrip()}>{tripUuid !== "0" && `Endre tur`}{tripUuid === "0" && `Registrer tur`}</Button>
                                        </Col>
                                    </Row>
                                </>
                            </MuiPickersUtilsProvider>
                        </form>
                    </Container>
                </Row>
            </Container>
            <Modal size="lg" show={showAddField} onHide={() => {
                setShowAddField(false);
                setCurrentExtraField(null)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentExtraField ? 'Endre felt' : 'Legg til felt'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddField ref={newFieldRef} valid={setAddFieldValid}
                              value={currentExtraField ? extraFields[currentExtraField] : null}/>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-around mt-3">
                        <Button onClick={() => {
                            setShowAddField(false);
                            setCurrentExtraField(null)
                        }}>
                            Avbryt
                        </Button>
                        <Button onClick={addField} disabled={!addFieldValid}>
                            {currentExtraField ? 'Endre' : 'Legg til'}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
};

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(CreateTrip);

export default withRouter(connected);
