import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {loginRequest} from "./actions";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBIcon,
  MDBView,
  MDBAlert
} from '../../../mdbreact'
import "./index.css";
import {ButtonBlack} from "../../../common/components/color-button";
import TextField from "@material-ui/core/TextField";
import Modal from "react-bootstrap/Modal";
import {showInfo} from "../../../common/lib/show-toast";
import {Fetch} from "../../../common/lib/api-fetch";
import {API_BASE_URL} from "../../../constants";



const AuthLogin = ({loginRequest, login}) => {
  const [form, setForm] = useState({
    email: {changed: false, value: ''},
    password: {changed: false, value: ''}
  });
  const [resetPassword, setResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');

  const submit = (event) => {
    event.preventDefault();
    loginRequest({email: form.email.value, password: form.password.value});
  };

  const changeHandler = event => {
    setForm({
      ...form,
      [event.target.name]: {changed: true, value: event.target.value}
    })
  };

  const doResetPassword = async () => {
    try {
      await Fetch(API_BASE_URL + "/auth/resetPasswordRequest", 'POST', {email: resetEmail}).then(result => {
          setResetPassword(false);
          showInfo("Forespørsel har blitt sendt");
        }
      );
    } catch (error) {
      console.log("Could not send request");
      setResetPassword(false);
      showInfo('Kunne ikke sende forespørsel');
    }
  }


  return (
    <form className="needs-validation" onSubmit={submit} noValidate>
      <div className="classic-form-page" id="login">
        <MDBView>
          <MDBMask
            className="d-flex justify-content-center align-items-center"
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                  <MDBCard className="white black-text">
                    <MDBCardBody>
                      <div className="form-header white black-text">
                        <h3><MDBIcon icon="user"
                                     className="mt-2 mb-2"/> Login:</h3>
                      </div>
                      <TextField
                        id="email"
                        label="Brukernavnet"
                        name={"email"}
                        placeholder="Brukernavn"
                        margin="normal"
                        variant="outlined"
                        value={form.email.value || ''}
                        onChange={changeHandler}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                      <TextField
                        id="password"
                        label="Passordet ditt"
                        name={"password"}
                        placeholder="Passordet ditt"
                        margin="normal"
                        variant="outlined"
                        type="password"
                        value={form.password.value || ''}
                        onChange={changeHandler}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                      <div className="text-center mt-3 black-text">
                        <ButtonBlack
                          type="submit">LOGIN</ButtonBlack>
                        <p className="black-text mt-2"
                           style={{fontWeight: 300, fontSize: '0.7rem', cursor: 'pointer'}}
                           onClick={() => setResetPassword(true)}>Glemt
                          passordet ditt?</p>
                      </div>
                      <MDBContainer>
                        {login.errors.length > 0 ?
                          <MDBAlert color="danger" dismiss>
                            {login.errors}
                          </MDBAlert>
                          : null}

                      </MDBContainer>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
        <Modal show={resetPassword} onHide={() => setResetPassword(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Mistet passordet ditt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Skriv inn epost-adressen din, og du vil bli tilsendt en lenke for å endre passordet ditt.
            <MDBInput
              value={resetEmail}
              name="resetEmail"
              onChange={(event) => setResetEmail(event.target.value)}
              label="Epost-adresse"
              icon="lock"
              iconClass="white-text">
            </MDBInput>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-around">
              <ButtonBlack onClick={() => doResetPassword()}>
                Be om nytt passord
              </ButtonBlack>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  )

}

AuthLogin.propTypes = {
  loginRequest: PropTypes.func,
  login: PropTypes.shape({
    requesting: PropTypes.bool,
    successful: PropTypes.bool,
    messages: PropTypes.string,
    errors: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  login: state.auth.login,
});

const connected = connect(mapStateToProps, {loginRequest})(AuthLogin);

export default withRouter(connected);
