import React, {Fragment, useMemo} from "react";
import styled from "styled-components";

import TablePaginationNav from "./TablePaginationNav";
import LoadingSpinner from "../layout/LoadingSpinner";
import SortIcon from "../icons/SortIcon";
import {TableFooter} from "../layout/TableFooter";

const TripMembersTable = ({
                              getTableProps,
                              headerGroups,
                              getTableBodyProps,
                              page,
                              prepareRow,
                              pageCount,
                              previousPage,
                              canPreviousPage,
                              nextPage,
                              canNextPage,
                              pageOptions,
                              gotoPage,
                              pageIndex,
                              isLoading,
                              rows
                          }) => {
    const showColumn = useMemo(() => {
        const result = {};
        if (Array.isArray(headerGroups)) {
            for (const hg of headerGroups) {
                for (const column of hg.headers) {
                    if ("showOnlyOnData" in column) {
                        result[column.id] = page.some(row => row.values[column.id]);
                    } else {
                        result[column.id] = true;
                    }
                }
            }
        }
        return result;
    }, [, headerGroups, page])

    return (
        <>
            <TableContainer>
                <TableStyled {...getTableProps()}>
                    <thead>
                    {headerGroups.map((group, index) => (
                        <Fragment key={`headerGroupFragment-${index}`}>
                            <tr {...group.getHeaderGroupProps()}>
                                {group.headers.map((column) =>
                                    <>
                                        {showColumn[column.id] ?
                                            <th
                                                {...column.getHeaderProps({
                                                    ...column.getSortByToggleProps(),
                                                    style: {
                                                        width: column.width,
                                                    }},
                                                )}
                                            >
                                                <TB>{column.render("Header")}</TB>

                                                {column.canSort ? (
                                                    <SortIcon
                                                        sort={
                                                            column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? "desc"
                                                                    : "asc"
                                                                : ""
                                                        }
                                                    />
                                                ) : null}
                                            </th> : null
                                        }</>
                                )}
                            </tr>
                        </Fragment>
                    ))}
                    </thead>

                    {page && page.length ? (
                        <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);

                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) =>
                                        <>
                                            {
                                                showColumn[cell.column.id] &&
                                                <td {...cell.getCellProps({style: {
                                                        width: cell.column.width,
                                                    }})}>
                                                    <T>{cell.render("Cell")}</T>
                                                </td>
                                            }
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                        </tbody>
                    ) : null}
                </TableStyled>
            </TableContainer>

            <TableFooter>
                {pageCount > 1 && (
                    <TablePaginationNav
                        pageCount={pageCount}
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                    />
                )}
                <div></div>
                <div>
                    <T>Antall: {rows?.length ? rows.length : 0}</T>
                </div>
            </TableFooter>

            {isLoading ? (
                <DashboardSpinner/>
            ) : !page.length ? (
                <NothingToShow>
                    <TB>{"Ingenting å vise"}</TB>
                </NothingToShow>
            ) : null}
        </>
    );
};

export default TripMembersTable;

const DashboardSpinner = () => (
    <section style={{height: "30vh"}}>
        <LoadingSpinner light/>
    </section>
);

const NothingToShow = styled.section`
  min-height: 20rem;
  display: grid;
  place-items: center;

  opacity: 0;
  animation: fadeIn 500ms ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

const StandardTableStyle = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    border-bottom: 1px solid #0d0d0d;
    height: 2.5rem;
    padding-right: 1rem;

    .SortIcon {
      margin-left: 0.35rem;
    }
  }

  th {
    user-select: none;
    white-space: nowrap;
  }

  td {
    min-width: 5rem;
    max-width: 20rem;
    overflow-wrap: break-word;
  }

  opacity: 0;
  animation: fadeIn 500ms ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

const TableContainer = styled.article`
  overflow: auto;
  margin-top: 2rem;
`;

const TableStyled = styled(StandardTableStyle)`
  min-width: 60rem;

  tr {
    cursor: pointer;

    td {
      vertical-align: top;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      img {
        max-width: 70px;
        max-height: 70px;
      }
    }

    td:first-child {
      text-align: left;
    }
  }
`;

const T = styled.span`
`

const TB = styled.span`
`;