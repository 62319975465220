import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {makeStyles} from "@material-ui/core";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import _ from "lodash";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh',
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: '0',
        minWidth: 120,
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none',
        margin: '0',
        padding: '0',
        display: 'block'
    },
    iframeDiv: {
        height: '100vh',
    }
}));

// https://travefy.com/trip/6yw9rquw4vnwqz2azxzweuvgt23c4va
const TripProgram = ({}) => {
    const classes = useStyles();
    const [iframeLink, setIframeLink] = useState("");

    let trip = useRouteMatch({
        path: "/tur/:uuid/",
        sensitive: true
    });
    const [tripUuid, setTripUuid] = useState(trip ? trip.params.uuid : "0");

    const fetchTripData = async () => {
        await Fetch(API_BASE_URL + "/trip/" + tripUuid, 'GET', {}).then(result => {
           setIframeLink(result.data.programUrl);
        })
    };

    useEffect(() => {
        if (tripUuid!=="0")
            fetchTripData();
    }, [tripUuid])

    return (
        <>
            <Container className={classes.root}>
                <Row className="justify-content-center setup-trip" md={8}>
                    <Container>
                        <div className={classes.iframeDiv}>
                            {iframeLink !== "" &&
                             <iframe className={classes.iframe} src={iframeLink}></iframe>
                            }

                            {iframeLink === "" &&
                            <div>
                                Ikke noe program registrert
                            </div>
                            }
                        </div>
                    </Container>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(TripProgram);

export default withRouter(connected);
