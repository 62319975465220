import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import useInfoForm from "./useInfoForm";
import Container from "react-bootstrap/Container";
import {FieldsToRows} from "../information/infoFields";
import {makeStyles} from "@material-ui/core";
import Row from "react-bootstrap/Row";
import _ from 'lodash';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh'
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const AddField = ({valid, value}, ref) => {
    const classes = useStyles();
    const [values, setValues] = useState({
        type: {
            label: 'Type',
            type: 'select',
            value: '',
            required: 'Du må velge type',
            possibleValues: [{key: 'text', value: 'tekst'}, {key: 'number', value: 'nummer'}, {key: 'mobile', value: 'mobil'}, {key: 'boolean', value: 'Ja/Nei'}],
            position: 1
        },
        label: {
            label: 'Navn',
            type: 'text',
            value: '',
            regex: '\\w',
            required: 'Du er nødt til å ha et navn',
            helperText: 'Det som vil vises på feltet slik Navn vises her',
            position: 2
        },
        helperText: {
            label: 'Hjelpetekst',
            type: 'text',
            value: '',
            helperText: 'Hjelpetekst til feltet. Vil vises som denne teksten.',
            position: 3
        },
        position: {
            label: 'Posisjon',
            type: 'number',
            value: '',
            helperText: 'Posisjonen for feltet',
            position: 4
        }
    });

    const form = useInfoForm(values, setValues);

    useEffect(() => {
        if (value) {
            const newValues = _.cloneDeep(values);
            newValues.type.value = value.type;
            newValues.label.value = value.label;
            newValues.helperText.value = value.helperText;
            newValues.position.value = value.position;
            setValues(newValues);
        }
    }, [value])

    useImperativeHandle(ref, () => ({
        hasErrors: () => form.hasErrors(),
        values
    }));

    useEffect(() => {
        valid(!form.hasErrors());
    }, [values])

    return (
        <Container>
            <Row className="justify-content-center setup-trip" md={10}>
                <Container>
                    <form noValidate autoComplete="off">
                        {
                            FieldsToRows({values, classes, infoForm: form})
                        }
                    </form>
                </Container>
            </Row>
        </Container>
    )
};

export default forwardRef(AddField);
