import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBBtn
} from "../../mdbreact";
import {logoutRequest} from "../auth/actions";

class Header extends React.Component {
    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    logout = (event) => {
        this.props.logoutRequest();
    }

    goHome = () => {
        this.props.history.push("/dashboard");
    }

    admin = () => {
        this.props.history.push("/admin");
    }

    render() {
        const { user, pathname, isVisible } = this.props;
        return (
          <div>
          { isVisible &&
            <MDBNavbar id="header" expand="md">
                <MDBNavbarBrand onClick={this.goHome} className="pointer">
                    <img src="/system/travel-event-logo.png" alt={"TravelEvent logo"} style={{height: '25px'}} className="img-fluid"/>
                    <strong className="pl-3">Deltaker</strong>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={this.toggleCollapse}/>
                <MDBCollapse id="navbarCollapse" isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav left>
                        <MDBNavItem className={pathname==='/dashboard' ? 'active' : ''}>
                            <MDBNavLink className="black-text" to="/dashboard">Hjem</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem className={pathname==='/setup' ? 'active' : ''}>
                            <MDBNavLink className="black-text" to="/setup">Oppsett</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                    <MDBNavbarNav right>
                      <MDBNavItem>
                        <MDBBtn color="white" size="sm" onClick={() => this.props.history.push("/personvern")}>Personvern</MDBBtn>
                      </MDBNavItem>
                        <MDBNavItem>
                            { user && user.roles.some(obj => obj.roleName==='ADMIN')
                                ? <MDBBtn flat size="sm" onClick={this.admin}>Admin</MDBBtn>
                                : ""
                            }
                        </MDBNavItem>
                        <MDBNavItem>
                            { user ? <MDBBtn flat size="sm">{user.firstName}</MDBBtn> : ""}
                        </MDBNavItem>
                        <MDBNavItem active>
                            {user ? <MDBBtn color="white" size="sm" onClick={this.logout}>Logg ut</MDBBtn> : null}
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
               }
          </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.module.user,
    pathname: state.router.location.pathname,
    isVisible: state.header.isVisible
});

const connected = connect(mapStateToProps, {logoutRequest})(Header);

export default withRouter(connected);
