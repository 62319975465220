import React from 'react'
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const TablePaginationNav = ({
                                pageCount,
                                previousPage,
                                canPreviousPage,
                                nextPage,
                                canNextPage,
                                pageOptions,
                                gotoPage,
                                pageIndex,
                            }) => {
    return (
        <Container>
            <TextNavButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                <TB link>{"Forrige"}</TB>
            </TextNavButton>

            {pageCount < 7 ? (
                pageOptions.map((option) => (
                    <PageButton
                        key={`option-${option}`}
                        onClick={() => gotoPage(option)}
                        disabled={option === pageIndex}
                    >
                        <TB link>{option + 1}</TB>
                    </PageButton>
                ))
            ) : (
                <>
                    {pageIndex > 1 && (
                        <>
                            <PageButton onClick={() => gotoPage(0)}>
                                <TB link>1</TB>
                            </PageButton>
                            <Dots>
                                <T>...</T>
                            </Dots>
                        </>
                    )}

                    {pageOptions
                        .slice(pageIndex === 0 ? 0 : pageIndex - 1, pageIndex + 2)
                        .map((option) => (
                            <PageButton
                                key={`option-${option}`}
                                onClick={() => gotoPage(option)}
                                disabled={option === pageIndex}
                            >
                                <TB link>{option + 1}</TB>
                            </PageButton>
                        ))}

                    {pageIndex < pageCount - 2 && (
                        <>
                            <Dots>
                                <T>...</T>
                            </Dots>
                            <PageButton onClick={() => gotoPage(pageCount - 1)}>
                                <TB link>{pageCount}</TB>
                            </PageButton>
                        </>
                    )}
                </>
            )}

            <TextNavButton onClick={() => nextPage()} disabled={!canNextPage}>
                <TB link>{"Neste"}</TB>
            </TextNavButton>
        </Container>
    );
};

export default TablePaginationNav;

const T = styled.span`
`;

const TB = styled.span`
`;

const CleanButton = styled(Button)`
`;

const Container = styled.article`
  margin-top: 1rem;
`;

const TextNavButton = styled(CleanButton)`
  user-select: none;
  height: 2.5rem;
  padding: 0 0.5rem;

  :first-child {
    padding-left: 0;
  }

  :disabled {
    > ${TB} {
      cursor: initial;
      color: #0d0d0d;
    }
  }
`;

const PageButton = styled(CleanButton)`
  user-select: none;
  height: 2.5rem;
  padding: 0 1rem;

  :disabled {
    background: #3f51b5;
    cursor: initial;
    color: white;

    > ${TB} {
      cursor: initial;
      color: #3f51b5;
    }
  }
`;



const Dots = styled.span`
  user-select: none;
  padding: 0rem;
`;

