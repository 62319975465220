import {connect} from "react-redux";
import {withRouter} from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import TextField from "@material-ui/core/TextField";
import TripMembersTable from "./TripMembersTable";
import styled from "styled-components";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh'
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));



const Dashboard = ({history}) => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [data, setData] = useState({columns: [], rows: []})

    const tripTableColumns = useMemo(() => ([
        {
            Header: "Navn",
            accessor: "name",
            width: 200
        },
        {
            Header: "Firma",
            accessor: 'companyName',
            width: 200
        },
        {
            Header: "Start dato",
            accessor: "fromDate",
            width: 200,
            Cell: ({value}) => (new Date(value)).toLocaleDateString("no")
        },
        {
            Header: '',
            accessor: 'change',
            width: 100,
            disableSortBy: true,
            Cell: ({row}) => <Button color="primary"
                                     onClick={() => history.push("/tur/" + row.original.uuid + "/rediger")}>Endre</Button>
        },
        {
            Header: '',
            accessor: 'lookup',
            width: 100,
            disableSortBy: true,
            Cell: ({row}) => <Button color="secondary" onClick={() => history.push("/tur/"+row.original.uuid)}>Gå til tur</Button>
        }
    ]), [history]);

    const [fixedData, setFixedData] = useState({
        columns: tripTableColumns,
        rows: []
    });
    const [fixedOldData, setFixedOldData] = useState({
        columns: tripTableColumns,
        rows: []
    })

    const fetchData = async () => {
        await Fetch(API_BASE_URL + "/trip/open", "GET").then(result => {
            if (!result) return;
            let newFixedData = {
                columns: tripTableColumns,
                rows: []
            }
            for(let trip of result.data) {
                let row = {
                    uuid: trip.uuid,
                    name: trip.name,
                    companyName: trip.companyName,
                    fromDate: trip.fromDate
                };
                let toDate = new Date(trip.toDate);
                if (toDate>=Date.now()) {
                    newFixedData.rows.push(row);
                }
            }
            setFixedData(newFixedData);
        })
    }

    const fetchArchive = async () => {
        await Fetch(API_BASE_URL + "/trip", "GET").then(result => {
            if (!result) return;
            let newFixedOldData = {
                columns: tripTableColumns,
                rows: []
            }
            for (let trip of result.data) {
                let row = {
                    uuid: trip.uuid,
                    name: trip.name,
                    companyName: trip.companyName,
                    fromDate: trip.fromDate
                };
                let toDate = new Date(trip.toDate);
                if (toDate < Date.now()) {
                    newFixedOldData.rows.push(row);
                }
            }
            setFixedOldData(newFixedOldData);
        })
    }



    useEffect(() => {
        fetchData();
        fetchArchive();
    }, [])

    const newTrip = () => {
        history.push("/tur/0/rediger");
    }

    useEffect(() => {
        if (tab===0)
            setData(fixedData);
        else if (tab===1)
            setData(fixedOldData)
    }, [tab, fixedData, fixedOldData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,

        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        gotoPage,
        pageOptions,
        pageCount,

        setFilter,
        setAllFilters,
        setGlobalFilter,
        state: {pageIndex},
    } = useTable(
        {columns: data.columns, data: data.rows, initialState: {pageSize: 20}},
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <>
            <Container className={classes.root}>
                <Row className="justify-content-center" md={8}>
                    <Container>
                        <Tabs
                            value={tab}
                            onChange={(e,v) => setTab(v)}
                            indicatorColor="primary"
                            centered
                        >
                            <Tab label="Pågående turer"/>
                            <Tab label="Arkiv"/>
                        </Tabs>
                        <StyledTable>
                            <label>
                                <TextField id={"search"} name={"search"} placeholder={"Søk"} onChange={(e) => setGlobalFilter(e.target.value)}></TextField>
                            </label>
                            <TripMembersTable
                                getTableProps={getTableProps}
                                headerGroups={headerGroups}
                                getTableBodyProps={getTableBodyProps}
                                page={page}
                                prepareRow={prepareRow}
                                pageCount={pageCount}
                                previousPage={previousPage}
                                canPreviousPage={canPreviousPage}
                                nextPage={nextPage}
                                canNextPage={canNextPage}
                                pageOptions={pageOptions}
                                gotoPage={gotoPage}
                                pageIndex={pageIndex}
                                isLoading={false}
                                rows={rows}
                            />
                        </StyledTable>
                        <div className="fixed-action-btn button-create">
                            <div onClick={newTrip} className="btn-floating btn-lg site-red">
                                <i className="fas fa-plus"></i>
                            </div>
                        </div>
                    </Container>
                </Row>
            </Container>
        </>
    )
}

const StyledTable = styled.div`
    margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  >label {
      display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(Dashboard);

export default withRouter(connected);
