import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './mdbreact/dist/css/mdb.css';

import {applyMiddleware, createStore, compose} from 'redux';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import IndexReducer from './index-reducer';
import IndexSagas from './index-sagas';
import {createBrowserHistory} from 'history';
import {routerMiddleware, ConnectedRouter} from 'connected-react-router';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const store = createStore(
    IndexReducer(history),
    composeSetup(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware),
    ),
);

// Begin our Index Saga
sagaMiddleware.run(IndexSagas);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App></App>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
