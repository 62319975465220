import {connect} from "react-redux";
import {withRouter} from "react-router";
import {makeStyles, useTheme} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import LayoutMenu from "../../modules/layout/LayoutMenu";
import useInfoForm from "../../modules/forms/useInfoForm";
import {createInformationField} from "../../modules/information/infoFields";
import Button from "@material-ui/core/Button";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {toast} from "react-toastify";
import _ from 'lodash';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        top: 59
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: '100vh'
    },
}));

const SystemSetup = () => {
    const classes = useStyles();
    const [showId, setShowId] = useState(0);

    const [values1, setValues1] = useState({
        unconfirmedEmail: {
            label: 'Ubekreftet epost',
            type: 'editor',
            value: '',
            position: 1
        },
    })
    const [values2, setValues2] = useState({
        confirmedEmail: {
            label: 'Bekreftet epost',
            type: 'editor',
            value: '',
            position: 1
        },
    })
    const [values3, setValues3] = useState({
        frontpage: {
            label: 'Standard forside',
            type: 'editor',
            value: '',
            position: 1
        }
    })

    const form1 = useInfoForm(values1, setValues1);
    const form2 = useInfoForm(values2, setValues2);
    const form3 = useInfoForm(values3, setValues3);

    const fetchSetupData = async () => {
        await Fetch(API_BASE_URL + "/trip/setup", "GET").then(result => {
            if (result) {
                let newValues1 = _.cloneDeep(values1);
                newValues1.unconfirmedEmail.value = result.data.unconfirmedEmail;
                setValues1(newValues1);
                let newValues2 = _.cloneDeep(values2);
                newValues2.confirmedEmail.value = result.data.confirmedEmail;
                setValues2(newValues2);
                let newValues3 = _.cloneDeep(values3);
                newValues3.frontpage.value = result.data.frontpage;
                setValues3(newValues3);
            } else {
                toast.error("Kunne ikke hente data");
            }
        })
    }

    const saveSetupData = async() => {
        let setupData = {
            unconfirmedEmail: values1.unconfirmedEmail.value,
            confirmedEmail: values2.confirmedEmail.value,
            frontpage: values3.frontpage.value
        }
        await Fetch(API_BASE_URL + "/trip/setup", "POST", setupData).then(result => {
            if (result.status===200) {
                toast.info("Lagret");
            } else {
                toast.error("Noe gikk galt ved lagring");
            }
        })
    }

    useEffect(() => {
        fetchSetupData();
    }, [])

    const drawer = (
        <div>
            <List>
                <ListItem button onClick={() => setShowId(0)} selected={showId === 0}>
                    <ListItemIcon><MailIcon/></ListItemIcon>
                    <ListItemText primary={values1.unconfirmedEmail.label}/>
                </ListItem>
                <ListItem button onClick={() => setShowId(1)} selected={showId === 1}>
                    <ListItemIcon><MailIcon/></ListItemIcon>
                    <ListItemText primary={values2.confirmedEmail.label}/>
                </ListItem>
                <ListItem button onClick={() => setShowId(2)} selected={showId === 2}>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary={values3.frontpage.label}/>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <LayoutMenu classes={classes} drawer={drawer}/>
            <main className={classes.content}>
                <div hidden={showId !== 0}>
                    {
                        createInformationField({values: values1, key: 'unconfirmedEmail', form: form1})
                    }
                    <Button className="my-2" color="secondary" variant="contained"
                            disabled={form1.hasErrors()} onClick={() => saveSetupData()}>Lagre</Button>

                </div>
                <div hidden={showId !== 1}>
                    {
                        createInformationField({values: values2, key: 'confirmedEmail', form: form2})
                    }
                    <Button className="my-2" color="secondary" variant="contained"
                            disabled={form2.hasErrors()} onClick={() => saveSetupData()}>Lagre</Button>

                </div>
                <div hidden={showId !== 2}>
                    {
                        createInformationField({values: values3, key: 'frontpage', form: form3})
                    }
                    <Button className="my-2" color="secondary" variant="contained"
                            disabled={form3.hasErrors()} onClick={() => saveSetupData()}>Lagre</Button>
                </div>

            </main>

        </div>
    );
}

const mapStateToProps = state => ({});
const connected = connect(mapStateToProps, {})(SystemSetup);
export default withRouter(connected);
