import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";
import React, {useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {logoutRequest} from "../auth/actions";

const TripHeader = ({user, logoutRequest}) => {
    const [logo, setLogo] = useState("");
    let trip = useRouteMatch({
        path: "/tur/:id/",
        sensitive: true
    });
    let tripId = trip ? trip.params.id : "0";

    useEffect(() => {
        if (tripId!=="0") {
            Fetch(API_BASE_URL+"/trip/"+tripId, "GET").then(result => {
               let data = result.data;
               console.log(data);
               setLogo(data.logoUuid ? data.logoUuid : '');
            })
        }
    }, [tripId]);

    return (
        <>
            <Navbar bg="white" expand="lg">
                <Navbar.Brand href="/dashboard">
                    <img src="/system/travel-event-logo.png" alt={"TravelEvent logo"} style={{height: '25px'}}
                         className="img-fluid"/>
                         <span className={"vl mx-2"}/>
                    {logo && logo!=="" &&
                    <img className="mb-2" src={API_BASE_URL + "/image/" + logo} style={{height: '30px'}} className="img-fluid"/>
                    }
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {
                            user &&
                            <>
                                <Nav.Link as={Link} to="/dashboard">Turliste</Nav.Link>
                                <Nav.Link as={Link} to="/setup">Oppsett</Nav.Link>
                            </>
                        }
                        {tripId !== "0" &&
                        <>
                            <Nav.Link as={Link} to={`/tur/${tripId}`}>Hjem</Nav.Link>
                            <Nav.Link as={Link} to={`/tur/${tripId}/program`}>Program</Nav.Link>
                            <Nav.Link as={Link} to={`/tur/${tripId}/paamelding`}>Påmelding</Nav.Link>
                            <Nav.Link as={Link} to={`/tur/${tripId}/deltakere`}>Deltakerliste</Nav.Link>
                        </>
                        }
                    </Nav>
                </Navbar.Collapse>
                {user &&
                <Navbar.Collapse id="right-navbar">
                    <Nav className="ml-auto">
                        <Nav.Link onClick={() => logoutRequest()}>Logg ut</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                }
            </Navbar>
        </>
    )
}

const mapStateToProps = state => ({
    user: state.auth.module.user,
});

const connected = connect(mapStateToProps, {logoutRequest})(TripHeader);

export default withRouter(connected);
