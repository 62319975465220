import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        secondary: {
            main: '#c62622'
        },
        error: {
            main: '#c62622'
        }
    }
});
