import React from "react";
import Header from "../header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        height: '100vh'
    }
}));

export const GDPR = () => {
    const classes = useStyles();
    return (
        <>
            <Header></Header>
            <Container className={classes.root}>
                <Row className="justify-content-center" md={8}>
                    <Container>
                        <Row className="justify-content-center mt-3">
                            <Col>
                                <h3>Personvernærklæring</h3>
                                <h5>Dine personopplysninger</h5>
                                <p>Travelevent As vet hvor viktig det er med integritet for våre
                                kunder og besøkende på nettsidene våre, og målet vårt med disse retningslinjene er å gi
                                deg en tydelig og gjennomsiktig beskrivelse av hvordan vi samler inn, bruker, viser,
                                overfører og lagrer opplysningene dine, slik at du føler deg sikker på at
                                personopplysningene dine er i trygge hender. Travelevent håndterer alle
                                personopplysninger i tråd med personopplysningsloven og EUs kommende
                                personvernforordning (GDPR).
                                </p>
                                <p>
                                    <a target="_blank" href={"https://www.travelevent.no/wp-content/uploads/2018/04/Personvern.pdf"}>Full erklæring</a>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    )
}
