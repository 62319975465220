import { USER_GET_ME_REQUESTING, USER_GET_ME_ERROR, USER_GET_ME_SUCCESS } from './constants'

const initialState = {
    user: {
        firstName: "",
        lastName: ""
    },
    errors: "",
    isUser: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_GET_ME_REQUESTING:
            return {
                ...state
            };

        case USER_GET_ME_SUCCESS:
            return {
                user: action.user,
                errors: "",
                isUser: true
            };

        case USER_GET_ME_ERROR:
            return {
                user: Object.assign({}, initialState.user),
                errors: action.error.toString(),
                isUser: false
            };

        default:
            return state
    }
};

export default reducer
