import React, {useEffect, useMemo, useState} from 'react';
import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {makeStyles} from "@material-ui/core";
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {showErrorText, showInfo} from "../../common/lib/show-toast";
import Button from "@material-ui/core/Button";
import useInfoForm from "../../modules/forms/useInfoForm";
import {FieldsToRows} from "../../modules/information/infoFields";
import Col from "react-bootstrap/Col";
import _ from 'lodash';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import './index.scss';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import TripMembersTable from "./TripMembersTable";
import styled from 'styled-components';
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh'
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const TripMembers = ({user, history}) => {


    const classes = useStyles();
    const [isUser, setIsUser] = useState(user.isUser);
    let trip = useRouteMatch({
        path: "/tur/:uuid",
        sensitive: true
    });
    let tripUser = useRouteMatch({
        path: '/tur/:uuid/deltakere/:userUuid',
        sensitive: true
    });
    const [tripUuid, setTripUuid] = useState(trip ? trip.params.uuid : "0");
    const [tripUserUuid, setTripUserUuid] = useState(tripUser ? tripUser.params.userUuid : '0');
    const [tripData, setTripData] = useState({});
    const [reloadData, setReloadData] = useState(false);
    const [onlyConfirmed, setOnlyConfirmed] = useState(false);
    const [onlyUnconfirmed, setOnlyUnconfirmed] = useState(false);
    const [deleteMember, setDeleteMember] = useState(false);
    const [onlyList, setOnlyList] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [sendSMS, setSendSMS] = useState(false);
    const [availableRooms, setAvailableRooms] = useState({singleRooms: 0, doubleRooms: 0, halfDoubleRooms: 0});
    const [sendEmailValue, setSendEmailValue] = useState({
        email: {
            label: 'Epost',
            type: 'editor',
            value: "Hei {{fornavn}}!",
            position: 2
        },
        subject: {
            label: 'Tittel',
            type: 'text',
            value: '',
            position: 1,
            required: 'Du må ha en tittel'
        }
    });
    const [sendSMSValue, setSendSMSValue] = useState({
        sms: {
            label: 'SMS',
            type: 'text',
            value: '',
            position: 1,
            required: 'Du må ha en tekst å sende',
            lines: 6
        }
    })

    const tripTableColumnsUser = useMemo(() => [
        {
            Header: 'Etternavn',
            accessor: 'lastname',
            width: 100
        },
        {
            Header: 'Fornavn',
            accessor: 'firstname',
            width: 100
        },
    ], []);

    const tripTableColumnCompany = useMemo(() => ({
        Header: 'Firmanavn',
        accessor: 'companyName',
        width: 100
    }), []);

    const tripTableColumnsAdmin = useMemo(() => ([
        {
            Header: 'status',
            accessor: 'status',
            width: 50,
            Cell: ({value, row}) => createStatusDropDown(row.original.uuid, value, tripData.uuid)
        },
        {
            Header: 'note',
            accessor: 'note',
            width: 100,
        },
        {
            Header: '',
            accessor: 'change',
            disableSortBy: true,
            width: 50,
            Cell: ({value, row}) => <Button color="primary"
                                            onClick={() => history.push("/tur/" + tripUuid + "/paamelding/" + row.original.uuid)}>Endre</Button>
        },
        {
            Header: '',
            accessor: 'send',
            disableSortBy: true,
            width: 50,
            Cell: ({value, row}) => <Button color="primary"
                                            onClick={() => resendEmail(row.original.uuid)}>Send</Button>
        },
        {
            Header: '',
            accessor: 'delete',
            disableSortBy: true,
            width: 50,
            Cell: ({value, row}) => <Button color="primary"
                                            onClick={() => setDeleteMember({
                                                uuid: row.original.uuid,
                                                firstname: row.values.firstname,
                                                lastname: row.values.lastname
                                            })}>Slett</Button>
        }
    ]), [tripData]);

    const valueDef = useMemo(() => ({
        userUuid: {
            label: 'Passord',
            type: 'text',
            value: '',
            position: 0,
            required: 'Du må fylle inn passord',
            helperText: 'Dette er et nummer på formatet xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
        }
    }), []);

    const emailForm = useInfoForm(sendEmailValue, setSendEmailValue);
    const smsForm = useInfoForm(sendSMSValue, setSendSMSValue);

    const [fixedData, setFixedData] = useState({
        columns: tripTableColumnsUser,
        rows: []
    });

    const [loginValues, setLoginValues] = useState(valueDef);
    let loginForm = useInfoForm(loginValues, setLoginValues);

    useEffect(() => {
        setIsUser(user.isUser);
    }, [user]);

    useEffect(() => {
        if (tripUser)
            setTripUserUuid(tripUser.params.userUuid);
    }, [tripUser])

    useEffect(() => {
        if (onlyConfirmed)
            setOnlyUnconfirmed(false);
    }, [onlyConfirmed]);

    useEffect(() => {
        if (onlyUnconfirmed)
            setOnlyConfirmed(false);
    }, [onlyUnconfirmed]);

    useEffect(() => {
        if (tripUuid !== '0') {
            Fetch(API_BASE_URL + "/trip/" + tripUuid + "/available", "GET").then(result => {
                setAvailableRooms(result.data);
            })
        }
    }, [tripUuid])

    useEffect(() => {
        if ('uuid' in tripData) {
            if (tripData.closedList && !isUser && tripUserUuid === "0") return;
            Fetch(API_BASE_URL + "/trip/" + tripUuid + "/members" + (tripUserUuid !== '0' ? "/" + tripUserUuid : ''), 'GET').then(result => {
                console.log("Res", result);
                if (result.data.length === 0) {
                    return;
                }
                const hasCompanyName = 'companyName' in result.data[0].extra;
                let newFixedData = {
                    columns: _.cloneDeep(tripTableColumnsUser),
                    rows: []
                };
                if (hasCompanyName)
                    newFixedData.columns.push(tripTableColumnCompany);
                if (isUser && !onlyList) newFixedData.columns = newFixedData.columns.concat(tripTableColumnsAdmin);

                const addMember = (member) => {
                    let row = {
                        firstname: member.firstname,
                        lastname: member.lastname
                    };
                    if (hasCompanyName)
                        row.companyName = member.extra.companyName;
                    if (isUser) {
                        row.uuid = member.uuid;
                        if (!onlyList) {
                            row.status = member.status;
                            row.note = member.note;
                            row.uuid = member.uuid;
                        }
                    }
                    newFixedData.rows.push(row);
                }
                for (let member of result.data) {
                    if (onlyConfirmed && member.status !== 'bekreftet') continue;
                    if (onlyUnconfirmed && member.status !== 'ubekreftet') continue;
                    addMember(member);
                    if ('companionFirstname' in member.extra && member.extra.companionFirstname !== '') {
                        let companion = {
                            firstname: member.extra.companionFirstname,
                            lastname: member.extra.companionLastname,
                            status: member.status,
                            note: member.note,
                            extra: {}
                        }
                        if (hasCompanyName)
                            companion.extra.companyName = member.extra.companyName;

                        if (isUser)
                            companion.uuid = member.uuid;
                        addMember(companion);
                    }
                }
                setFixedData(newFixedData);
            }).catch(err => {
                console.log(err);
                showErrorText("Noe gikk galt ved lasting av deltakerlisten");
            });
            setReloadData(false);
        }
    }, [tripData, tripUserUuid, isUser, reloadData, onlyConfirmed, onlyUnconfirmed, onlyList]);

    const handleStatusChange = (key, value, tripUuid) => {
        const newValue = value;
        Fetch(API_BASE_URL + "/trip/" + tripUuid + "/member/" + key + "/status", "POST", {status: newValue}).then(result => {
            setReloadData(true);
            showInfo("Du har endre status på deltaker");
        }).catch(err => {
            showErrorText("Feil ved endring av status");
        })
    }

    const resendEmail = (userUuid) => {
        return;
        Fetch(API_BASE_URL + "/trip/" + tripData.uuid + "/member/" + userUuid + "/resend", "GET").then(result => {
            showInfo("Epost sendt");
        }).catch(err => {
            showErrorText("Feil ved sending av epost");
        })
    }

    const createStatusDropDown = (key, value, tripUuid) => {
        const values = ['ubekreftet', 'bekreftet', 'avmeldt'];
        return (
            <>
                <Select
                    labelId="key"
                    id="key"
                    value={value}
                    onChange={(event) => handleStatusChange(key, event.target.value, tripUuid)}
                >
                    {values.map(v => (
                        <MenuItem key={v} value={v}>
                            {v}
                        </MenuItem>
                    ))}
                </Select>
            </>
        )
    }

    useEffect(() => {
        if (tripUuid !== "0") {
            Fetch(API_BASE_URL + "/trip/" + tripUuid, 'GET', {}).then(result => {
                let data = result.data;
                setTripData(data);
            }).catch(err => {
                showErrorText("Noe gikk galt ved henting av turdata");
                setTripData({});
            });
        }
    }, [tripUuid])

    const deleteMemberServer = () => {
        console.log("Deleting member on server");
        Fetch(API_BASE_URL + "/trip/" + tripUuid + "/member/" + deleteMember.uuid + "/delete", 'GET').then(result => {
            showInfo("Brukeren er slettet");
            setDeleteMember(false);
            setReloadData(true);
        }).catch(err => {
            showErrorText("Noe gikk galt ved sletting av bruker");
        })

    };

    const sendEmailServer = () => {
        Fetch(API_BASE_URL + "/trip/" + tripUuid + "/email", "POST", {
            email: sendEmailValue.email.value,
            subject: sendEmailValue.subject.value
        }).then(result => {
            setSendEmail(false);
            console.log(result);
            showInfo("Epost sendt til alle bekreftede deltakere. Antall: " + result.data.count);
        }).catch(err => {
            showErrorText("Noe gikk galt ved utsending av epost");
        })
    }

    const sendSMSServer = () => {
        Fetch(API_BASE_URL + "/trip/" + tripUuid + "/sms", "POST", {
            sms: sendSMSValue.sms.value,
        }).then(result => {
            setSendSMS(false);
            console.log(result);
            showInfo("SMS sendt til alle bekreftede deltakere. Antall: " + result.data.count);
        }).catch(err => {
            showErrorText("Noe gikk galt ved utsending av epost");
        })
    }

    useEffect(() => {
        if (sendEmail)
            emailForm.changeValue('subject', 'Informasjon fra Travelevent om turen ' + (tripData && 'name' in tripData ? tripData.name : ''));
    }, [sendEmail])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,

        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        gotoPage,
        pageOptions,
        pageCount,

        setFilter,
        setAllFilters,
        setGlobalFilter,
        state: {pageIndex},
    } = useTable(
        {columns: fixedData.columns, data: fixedData.rows, initialState: {pageSize: 20}},
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <>
            <Container className={classes.root}>
                <Row className="justify-content-center setup-trip" md={8}>
                    <Container>
                        {isUser &&
                            <>
                                <Grid container>
                                    <Grid item md={5}>
                                        <FormControlLabel
                                            key={'onlyList'}
                                            classes={{
                                                label: classes.label,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={onlyList}
                                                    onChange={() => setOnlyList(!onlyList)}
                                                    value={onlyList}
                                                    color="primary"
                                                />}
                                            label={'Kun liste'}
                                        />
                                        <FormControlLabel
                                            key={'onlyConfirmed'}
                                            classes={{
                                                label: classes.label,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={onlyConfirmed}
                                                    onChange={() => setOnlyConfirmed(!onlyConfirmed)}
                                                    value={onlyConfirmed}
                                                    color="primary"
                                                />}
                                            label={'Kun bekreftet'}
                                        />
                                        <FormControlLabel
                                            key={'onlyUnconfirmed'}
                                            classes={{
                                                label: classes.label,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={onlyUnconfirmed}
                                                    onChange={() => setOnlyUnconfirmed(!onlyUnconfirmed)}
                                                    value={onlyUnconfirmed}
                                                    color="primary"
                                                />}
                                            label={'Kun ubekreftet'}
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <Button onClick={() => setSendEmail(true)}>Send epost</Button>
                                        <Button onClick={() => setSendSMS(true)}>Send SMS</Button>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Grid container justify="flex-end">
                                            <Button href={API_BASE_URL + "/trip/" + tripUuid + "/flight"}
                                                    download>Flyliste</Button>
                                            <Button href={API_BASE_URL + "/trip/" + tripUuid + "/hotel"}
                                                    download>Hotelliste</Button>
                                            <Button href={API_BASE_URL + "/trip/" + tripUuid + "/travefy"}
                                                    download>Travefy</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {(!isUser && tripData && tripData.hiddenMemberList) ?
                            <div>Deltakerlisten er lukket</div>
                            :
                            <>
                                <Grid container>
                                    <Grid item md={12} className={"small"}>Ledige
                                        enkeltrom: {availableRooms.singleRooms}</Grid>
                                    <Grid item md={12} className={"small"}>Ledige
                                        dobbeltrom: {availableRooms.doubleRooms} {availableRooms.halfDoubleRooms > 0 ? `(+${availableRooms.halfDoubleRooms})` : ''}</Grid>
                                </Grid>
                                <div>
                                    {
                                        tripData && tripData.closedList && !isUser && tripUserUuid === '0' ?
                                            <form noValidate autoComplete="off">
                                                <>
                                                    Du må bruker passordet ditt. Du finner det i eposten du har fått
                                                    tilsendt.
                                                    {
                                                        FieldsToRows({
                                                            values: loginValues,
                                                            classes,
                                                            infoForm: loginForm
                                                        })
                                                    }
                                                    <Row>
                                                        <Col>
                                                            <Button color={"secondary"} variant="contained"
                                                                    disabled={loginForm.hasErrors()}
                                                                    onClick={() => history.push("/tur/" + tripUuid + "/deltakere/" + loginValues.userUuid.value)}>Login</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            </form>
                                            :
                                            <StyledTable>
                                                <label>
                                                    <TextField id={"search"} name={"search"} placeholder={"Søk"} onChange={(e) => setGlobalFilter(e.target.value)}></TextField>
                                                </label>
                                            <TripMembersTable
                                                getTableProps={getTableProps}
                                                headerGroups={headerGroups}
                                                getTableBodyProps={getTableBodyProps}
                                                page={page}
                                                prepareRow={prepareRow}
                                                pageCount={pageCount}
                                                previousPage={previousPage}
                                                canPreviousPage={canPreviousPage}
                                                nextPage={nextPage}
                                                canNextPage={canNextPage}
                                                pageOptions={pageOptions}
                                                gotoPage={gotoPage}
                                                pageIndex={pageIndex}
                                                isLoading={false}
                                                rows={rows}
                                            />
                                                </StyledTable>
                                    }
                                </div>
                            </>
                        }
                    </Container>
                </Row>
            </Container>
            <Dialog
                fullScreen={false}
                open={deleteMember !== false}
                onClose={() => setDeleteMember(false)}
                aria-labelledby="slett medlem"
            >
                <DialogTitle id="delete-member-title">{"Slett medlem"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vil du virkelig slette brukeren {deleteMember.firstname + " " + deleteMember.lastname}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDeleteMember(false)} color="primary" autoFocus>
                        Nei
                    </Button>
                    <Button onClick={() => deleteMemberServer()} color="primary">
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={false}
                open={sendEmail}
                onClose={() => setSendEmail(false)}
                aria-labelledby="Send epost"
            >

                <DialogTitle id="send-email-title">{"Send post til bekreftede deltakere"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        {
                            FieldsToRows({values: sendEmailValue, classes, infoForm: emailForm})
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setSendEmail(false)} color="primary" autoFocus>
                        Avbryt
                    </Button>
                    <Button onClick={() => sendEmailServer()} color="primary">
                        Send epost
                    </Button>
                </DialogActions>

            </Dialog>
            <Dialog
                fullScreen={false}
                open={sendSMS}
                onClose={() => setSendSMS(false)}
                aria-labelledby={"Send SMS"}
            >
                <DialogTitle id="send-sms-title">{"Send sms til bekreftede deltakere"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{width: "500px"}}>
                        {
                            FieldsToRows({values: sendSMSValue, classes, infoForm: smsForm})
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setSendSMS(false)} color="primary" autoFocus>
                        Avbryt
                    </Button>
                    <Button onClick={() => sendSMSServer()} color="primary">
                        Send sms
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const StyledTable = styled.div`
    margin-top: 10px;
  display: flex;
  flex-direction: column;
  >label {
      display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const mapStateToProps = state => ({
    user: state.user
});

const connected = connect(mapStateToProps, {})(TripMembers);

export default withRouter(connected);
